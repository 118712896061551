import React, { useState, useEffect } from 'react';
import { Divider, Col, Row } from 'antd';
import { json, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Popover, Segmented } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton = ({ navigateLink, setAddUser }) => {
	const { t } = useTranslation();
    const navigate = useNavigate();
    console.log("navigateLink",navigateLink)
    const handleBackClick = () => {
        if (setAddUser) {
            setAddUser(false);
        }
        // Navigate to the specified link
        navigate(`${navigateLink}`);

    };
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
     
    };


    return (

        <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%', // Ensures the button stays in the vertical center
        }}
            
       >
            <Button style={{
               
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight:"10px",
                backgroundColor: hovered ? '#0066b2' : '#F0F8FF',
                color: hovered ? '#F0F8FF' : '#1976d2',
                borderRadius: '4px',
            padding:' 6px 8px'

            }}
                onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave} startIcon={<ArrowBackIosIcon />} onClick={handleBackClick}>
				zur�ck
			</Button>
        </div>
    );
}
export default BackButton;