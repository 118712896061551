export const GOOGLE_API_KEY = "AIzaSyAAHm7DEwE1mM01dLkR8vzJLq4QcSHMdRM";

   //export const BASE_URL = "https://localhost:5001";
   //export const BLOB_URL = "https://caftesting.blob.core.windows.net";
   //export const SSO_URL = 'https://localhost:7275';
   //export const AUTHORIZATION_ENDPOINT = 'https://localhost:7275/home/authorize';
   //export const REDIRECT_URI = 'http://localhost:3000/signin-oidc';
   //export const CLIENT_ID = 'development'
   //export const AUTHORIZATION_HOMESITE_ENDPOINT = 'https://localhost:7275/accounts/register';
  

//Productions connections strings

 export const BASE_URL = "https://caf-cpov2-production.azurewebsites.net";
 export const BLOB_URL = "https://cafproduction.blob.core.windows.net";
 export const AUTHORIZATION_ENDPOINT = 'https://sso.chargeatfriends.com/Home/Authorize';
 export const REDIRECT_URI = 'https://cpo.chargeatfriends.com/signin-oidc';
 export const SSO_URL = 'https://sso.chargeatfriends.com';
 export const CLIENT_ID = 'platformnet6';
 export const AUTHORIZATION_HOMESITE_ENDPOINT = 'https://sso.chargeatfriends.com/accounts/register';


 ////Testing connections strings
 // export const BASE_URL = "https://caf-cpo-api-testing.azurewebsites.net";
 // export const BLOB_URL = "https://caftesting.blob.core.windows.net";
 // export const AUTHORIZATION_ENDPOINT = 'https://caf-sso-testing.azurewebsites.net/Home/Authorize';
 // export const SSO_URL = 'https://caf-sso-testing.azurewebsites.net/';
 // export const REDIRECT_URI = 'https://caf-cpo-react-testing.azurewebsites.net/signin-oidc';
 // export const CLIENT_ID = 'staging';
 // export const AUTHORIZATION_HOMESITE_ENDPOINT = 'https://caf-sso-testing.azurewebsites.net/accounts/register';
